.slideshowSlider {
  position: relative;
  height: 68vh;
  transition: ease 1000ms;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  /*transition: .3s ease;*/
}

.slide.active {
  opacity: 1;
  /*transition-duration: .2s;*/
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.image_title {
  z-index: 200;
  color: #fff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgb(0 0 0 / 10%), 0 0 5px rgb(0 0 0 / 10%), 0 1px 3px rgb(0 0 0 / 30%), 0 3px 5px rgb(0 0 0 / 20%), 0 5px 10px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 20%), 0 20px 20px rgb(0 0 0 / 15%);
}

.image_title h1 {
  font-size: 7em;
}

/* Buttons */

.slideshowDots {
  text-align: center;
  padding-top: 1%;
  padding-bottom: 2%;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #f36616;
}

@media (max-width: 813px) {
  .image_title h1 {
    font-size: 3em;
    padding-left: 5px;
    padding-right: 5px;
  }

  .slideshowSlider {
    height: 60vh;
  }
}