#contactPanel {
  padding: 2em 0em 32px;
  margin: 0%;
  flex-wrap: wrap;
  display: flex;
  box-sizing: border-box;
}

#contactLeftColumn {
  width: 50%;
  float: left;
  position: relative;
  display: block;
  padding-left: 15px;
  padding-right: 15px;
}

#contactRightColumn {
  width: 50%;
  float: left; 
  position: relative;
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  align-self: center;
  box-sizing: border-box;
}

#map {
  max-width: 100%;
  border-style: solid;
  border-color: #00a1df;
  border-width: 3px;
}

.contactLocation {
  font-weight: 600;
  font-size: 24px;
  color: #63af2c;
}

.contactIcon {
  color: #63af2c;
}

.contactText {
  color: #00a1df;
  font-size: 18px;
}

.contactLeftIndent {
  margin-left: 0.5em;
}

@media (max-width: 600px) {
  #contactLeftColumn {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  #contactRightColumn {
    padding-top: 15px;
    width: 100%;
    float: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}