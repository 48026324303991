* {
  margin: 0;
  padding: 0; 
}

.toggle-button {
  width: 40px;
  height: 35px;
  position: relative;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}
/*
.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
}
*/

.toggle-button:focus {
  outline: none;
}

/* .toggle-button__line {
  width: 30px;
  height: 2px;
  background: #fff;
} */

.toggle-button span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  background-color: #777;
}

.toggle-button span:nth-child(1) {
  top: 0px;
}

.toggle-button span:nth-child(2) {
  top: 15px;
}

.toggle-button span:nth-child(3) {
  top: 30px;
}

.toggle-button.open span:nth-child(1) {
  top: 15px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.toggle-button.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.toggle-button.open span:nth-child(3) {
  top: 15px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}