main { 
  margin-top: 56px;
  background-color: #eeeeee;
}

.routeHeader {
  font-weight: bold;
  color: #00a1df;
  font-size: 2em;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 0.25%;
}

.routeHeaderLineBreak {
  color: rgba(0, 161, 223, 0.75);
  border-style: solid;
  border-width: 0px 0px 3px; 
  width: 350px;
  margin: auto;
  margin-bottom: 2%;
}

.routeSubHeader {
  font-weight: bold;
  color: #000;
  font-size: 24px;
  text-align: center;
  padding-bottom: 0.25%;
}

@media (max-width: 350px) {
  .routeHeaderLineBreak {
    width: 100%;
  }
}