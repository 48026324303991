.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  z-index: 300;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
  background-color: #fff;
  width: 100%;
}

.toolbar__navigation a {
  height: 100%;
  margin-top: 1.3rem;
}

.toolbar__company-logo {
  float: left;
  height: 60%;
  margin-right: 1rem;
}

.toolbar__toggle-button {
  float: right;
  margin-left: 1rem;
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
}

.toolbar__company-name {
  float: left;
}

.toolbar__company-name a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
}

.toolbar__company-name a:hover {
  color: #555;
}

.spacer {
  flex: 1;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a:hover {
  color: #555
}

.menuItem {
  color: #ffffff;
  text-decoration: none;
}

.active {
  color: #555;
}

.desktop_nav {
  line-height: 56px;
}

a.nav_link {
  font-size: 16px;
  color: #f36616;
  height: 100%;
  padding: 1em;
} 

a.nav_link:hover {
  color: #00a1df;
}

@media (max-width: 813px) {
  .desktop_nav {
    display: none;
  }
}

@media (min-width: 813px) {
  .toolbar__toggle-button {
    display: none;
  }

  .toolbar__company-name {
    margin-left: 0;
  }
}

@media (max-width: 813px) {
  .contactInfo {
    display: none;
  }
}