#homeHeaderImage {
  color: #ffffff;
  background-size: cover;
  background-position: 50% 95%;
}

#homeHeaderContainer {
  padding-top: 75px; 
  padding-bottom: 100px; 
  padding-left: 15px; 
  padding-right: 15px;
  text-align: center;
}

#leftHomeHeaderColumn {
  float: left;
  width: 10%;
}

#middleHomeHeaderColumn {
  float: left;
  width: 80%;
}

#rightHomeHeaderColumn {
  width: 10%;
}

#homeHeaderCompanyName {
  color: #00a1df;
  font-size: 63px;
  font-weight: 600;
  text-shadow: 0 4px 3px rgb(0 0 0 / 40%), 0 8px 13px rgb(0 0 0 / 10%), 0 18px 23px rgb(0 0 0 / 10%);
}

#homeHeaderCompanyType {
  font-size: 32px; 
  font-weight: 400; 
  color: #ffffff; 
  text-shadow: 1px 1px 0 hsl(0deg 0% 55% / 60%), -1px -1px 1px rgb(0 0 0 / 67%);
}

#homeHeaderLineSeperator {
  color: rgba(0, 161, 223, 0.75);
  border-style: solid;
  border-width: 0px 0px 3px; 
  width: auto;
}

#homeHeaderCompanyMotto {
  line-height: 1.8em; 
  font-size: 24px;
  border-radius: 0px; 
  border-width: 2px; 
  border-image: initial;
  border-color: #0aa1df;
  background-color: rgba(0, 161, 223, 0);
}