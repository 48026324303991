.side-drawer {
  height: 100%;
  background: #FFF;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 201;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 56px;
  padding-left: 0px;
}

.side-drawer li {
  margin: 1rem 1.5rem;
  border-bottom: 2px solid #f36616;
  text-align: center;
  padding-bottom: 1.5rem;
}

.side-drawer li:last-of-type {
  margin: 1rem 1.5rem;
  border-bottom: none;
  text-align: center;
  padding-bottom: 1.5rem;
}

.side-drawer a {
  color: #f36616;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.side-drawer a:hover, 
.side-drawer a:active {
  color: #00a1df;
}

.side-drawer a.active {
  color: #00a1df;
}

@media (min-width: 813px) {
    .side-drawer {
        display: none;
    }
}